import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentRoutingModule } from './appointment-routing.module';
import { AppointmentListComponent } from './components/appointment-list/appointment-list.component';
import { AddAppointmentComponent } from './components/add-appointment/add-appointment.component';
import { EditAppointmentComponent } from './components/edit-appointment/edit-appointment.component';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { GridModule } from '@progress/kendo-angular-grid';
import { SharedModule } from 'src/app/shared/shared.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RecallsRoutingModule } from '../recalls/recalls-routing.module';
import {NgxMaskDirective, NgxMaskPipe} from 'ngx-mask';
import { PaginatorModule } from 'primeng/paginator';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TooltipModule } from 'primeng/tooltip';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';
import { FilterModule } from '@progress/kendo-angular-filter';

@NgModule({
  declarations: [
    AppointmentListComponent,
    AddAppointmentComponent,
    EditAppointmentComponent,
    ViewAppointmentComponent,
  ],
  imports: [
    CommonModule,
    AppointmentRoutingModule,
    DialogModule,
    RecallsRoutingModule,
    ButtonModule,
    PaginatorModule,
    ReactiveFormsModule,
    FormsModule,
    CalendarModule,
    SchedulerModule,
    SharedModule,
    AutoCompleteModule,
    DateInputsModule,
    GridModule,
    InputsModule,
    DropDownsModule,
    TooltipModule,
    NgxMaskPipe,
    NgxMaskDirective
  ]
})
export class AppointmentModule { }
