import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ContactService } from 'src/app/features/contact/services/contact.service';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { LookupProspectComponent } from 'src/app/features/white-board/components/add-whiteboard/lookup-prospect/lookup-prospect.component';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { AppointmentService } from '../../services/appointment.service';
import { AddAppointmentScreen } from '../../errormessage/addapppointment-screen';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';


@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.css']
})
export class AddAppointmentComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  _confirmBox: ConfirmBox;
  _userMessages: UserMessages;
  addappointmentForm: any;
  contactList: any = [];
  bsValue = new Date();
  @Input() fromViewProspect: any;
  prospectDeatils: any;
  _AddAppointmentScreen: AddAppointmentScreen;
  submitted: boolean = false;
  invalidRecallDate = false;
  invalidData: boolean = false;
  invalidDate: boolean = false;
  events: any[] = [];
  fromDateValue: any;
  id: any;
  prospectId: any;
  isDisable: boolean = false;
  recallData: any[] = [];
  _navigationPath: NavigationPath;
  accountList: any;
  prospectValue: string = '';
  contactNumber: any;
  unAthorizesd: any = '';
  showError: boolean = false;
  errorList: any = [];
  phoneInvalid: boolean = false;
  lookupData: any;
  lookupcomponent: any;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  masterProspectList: any[] = [];
  errorMessage: any;
  getSelectedUser: any;
  contactId: any;
  selectedContactId: any;
  minimumDate = new Date('01-01-2024');
  invalidtime: boolean = false;
  completeTimeForStart: any;
  completeTimeForEnd: any
  SelectedJobFairvalue: any;
  SelectedEventConferencevalue: any;
  SelectedCompanyEventvalue: any;
  eventTypeId: number = 0;
  isProspectRequired: boolean = false;
  SelectedProspectMeetingvalue: any;
  SelectedClientMeetingvalue: any;
  selectedEventName: string = '';
  isselectStatus: any;
  isAssignSelectEventValidorNot: boolean = false;
  isCancel: boolean = false;
  lookupFilterData: any
  disableJobFairFlag: boolean = true;
  hideContactField: boolean = true;
  contactNumberFlag: boolean = true;
  isValidCompanyName: boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private _createsaveAppointmentsUrl: AppointmentService,
    public _activeModal: NgbActiveModal,
    private _datepipe: DatePipe,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    public profileInfoService: ProfileInfoService,
    private _contactService: ContactService,
    private _ProspectListService: ProspectListService,
    private modalService: NgbModal,
    private _addWBAService: WhiteBoardActivityService,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    public _amountPipe: CustomeAmountPipe,
    public ValidateKey: KeyboardValidation,
  ) {
    this._confirmBox = new ConfirmBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._AddAppointmentScreen = new AddAppointmentScreen();
    this.addappointmentForm = this._fb.group({
      prospectId: ['', Validators.required],
      subject: ['', Validators.required],
      companyName: ['', Validators.required],
      typeofEventName: ['', Validators.required],
      typeOfVisit: ['', Validators.required],
      contactId: ['', Validators.required],
      contactNumber: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      opportunitySize: [''],
      notes: ['', Validators.maxLength(500)],
      dateofAppointment: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
      this.errorList.push(this.unAthorizesd)
    })
  }

  typeOfEvent(event: any) {
    let value1 = event.target.value;
    this.disableJobFairFlag = true;
    this.isProspectRequired = true;
    this.hideContactField = true;
    if (value1 == 'Job Fair') {
      this.SelectedJobFairvalue = 'Job Fair'
      this.eventTypeId = 1;
      this.contactNumberFlag = false;
      this.disableJobFairFlag = false;
      this.hideContactField = false;
      this.isProspectRequired = false;
      this.addappointmentForm.controls.companyName.errors == null;
      this.selectedEventName = '';
      this.checkValidationForJobFair(value1);
    } else if (value1 == 'Event / Conference') {
      this.SelectedEventConferencevalue = 'Event / Conference'
      this.eventTypeId = 2;
      this.contactNumberFlag = false;
      this.disableJobFairFlag = true;
      this.hideContactField = false;
      this.isProspectRequired = false;
      this.addappointmentForm.controls.companyName.errors == null;
      this.selectedEventName = '';
      this.checkValidationForJobFair(value1);
    }
    else if (value1 == 'Company Event') {
      this.SelectedCompanyEventvalue = 'Company Event'
      this.eventTypeId = 3;
      this.contactNumberFlag = false;
      this.disableJobFairFlag = false;
      this.hideContactField = false;
      this.isProspectRequired = false
      this.addappointmentForm.controls.companyName.errors == null;
      this.selectedEventName = '';
      this.checkValidationForJobFair(value1);
    }
    else if (value1 == 'Prospect Meeting') {
      this.SelectedProspectMeetingvalue = 'Prospect Meeting'
      this.eventTypeId = 4;
      this.isProspectRequired = true;
      this.selectedEventName = 'Prospect';
      this.disableJobFairFlag = true;
      this.hideContactField = true;
      this.contactNumberFlag = true;
      this.checkValidationForProspectMeeting(value1);
    } else if (value1 == 'Client Meeting') {
      this.SelectedClientMeetingvalue = 'Client Meeting'
      this.eventTypeId = 5;
      this.isProspectRequired = true;
      this.contactNumberFlag = true;
      this.disableJobFairFlag = true;
      this.hideContactField = true;
      this.selectedEventName = 'Client';
      this.checkValidationForProspectMeeting(value1);
    }

  }

  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 50
    }
    if (value === "companyName") {
      this.getProspectList()
    }
  }

  //Loading prospect List.
  getProspectList() {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "prospectName",
      "searchText": "",
      "filterJson": "",
      "gridFilterJson": ""
    }

    // this._ProspectListService.prospectPagination(data).subscribe(
    //   (resp: any) => {
    //     if (resp) {
    // this.masterProspectList = resp.items[0].prospectList;

    // this.lookupData = {
    //   data: this.masterProspectList,
    //   total: resp.totalRecord
    // };

    const modalRef = this.modalService.open(LookupProspectComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {

    })
    modalRef.componentInstance.lookupFilterData = this.lookupFilterData;
    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.hideAddButton = true;
    modalRef.componentInstance.selectedEventName = this.selectedEventName;

    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.isselectStatus = data.prospectInformation.status;
        this.addappointmentForm.controls['companyName'].patchValue({
          id: data.id,
          prospectName: data.prospectName
        });
        this.lookupFilterData = data.prospectName
        this.selectItem(data, 'companyName');
      }
    });
    //     }
    //   },
    //   (error: any) => {

    //   }
    // );
  }

  selectItem(item: any, type: any) {
    this.prospectId = item?.id;
    this.prospectValue = item.prospectName;
    this.lookupFilterData = item.prospectName;
    if (this.prospectId) {
      this.getContactListForProspect(this.prospectId);
    }
    if (type == 'companyName') {
      this.addappointmentForm.get('prospectId').setValue(this.prospectId);
    }

  }

  lookUpDataFilterChanges(val: any) {
    this.lookupFilterData = "";
    this.addappointmentForm.controls.prospectId.value = ''
  }

  getCompanyList(fieldName: any, val: any) {
    this.lookupFilterData = val.query;
    if (val.query.length > 4) {
      if (fieldName && val.query) {
        let encode = val.query.replaceAll('&', '%26')
        this._addWBAService.getTypeheadList(fieldName, encode).subscribe(resp => {
          if (fieldName == 'companyName') {
            this.accountList = resp.prospectList;
          }
        })
      }
    }
  }

  // get contact list using prospect by id
  getContactListForProspect(prospectId: any) {
    this._contactService.contactsList(prospectId).subscribe((resp) => {
      if (resp) {
        this.contactList = resp.contactList;
      }
    });
  }

  changeProspectValue() {
    this.addappointmentForm.controls.prospectId.value = ''
  }



  saveAppointment() {
    this.submitted = true;
    this.isValidCompanyName = false;
    let data = this.addappointmentForm.value;

    if (this.addappointmentForm.value.typeofEventName == "Job Fair" || this.addappointmentForm.value.typeofEventName == "Event / Conference" || this.addappointmentForm.value.typeofEventName == "Company Event") {
      this.addappointmentForm.controls.prospectId.status = 'VALID';
      this.addappointmentForm.controls.companyName.status = 'VALID';
      this.addappointmentForm.controls.companyName.errors == null;
      this.addappointmentForm.controls.opportunitySize.status = 'VALID';
    } else {
      this.addappointmentForm.controls.prospectId.status = 'INVALID';
      this.addappointmentForm.controls.companyName.status = 'INVALID';
      this.addappointmentForm.controls.opportunitySize.status = 'INVALID';
    }
    if (this.addappointmentForm.controls.contactId.value == '0') {
      this.addappointmentForm.get('contactNumber')?.setValidators(null);
      this.addappointmentForm.get('contactNumber')?.updateValueAndValidity();
      this.addappointmentForm.controls.contactNumber.status = 'VALID';
      this.contactNumberFlag = false
    }
    var dateValue = this._datepipe.transform(data.dateofAppointment, 'yyyy-MM-dd')
    var setTimeForStart = this._datepipe.transform(data.startTime, 'HH:mm:ss')
    var setTimeForEnd = this._datepipe.transform(data.endTime, 'HH:mm:ss')
    //local to utc for start time
    if (data.startTime) {
      var dateTime = dateValue + ' ' + setTimeForStart;
      var newDate = new Date(dateTime);
      var dateValue1 = this._datepipe.transform(newDate, 'yyyy-MM-dd')
      var setTime = this._datepipe.transform(newDate, 'HH:mm:ss')
      this.completeTimeForStart = dateValue1 + 'T' + setTime + 'Z';
    }


    // local to utc for End Time
    if (data.endTime) {
      var endTime = dateValue + ' ' + setTimeForEnd;
      var newDate = new Date(endTime);
      var dateValue2 = this._datepipe.transform(newDate, 'yyyy-MM-dd')
      var setTime = this._datepipe.transform(newDate, 'HH:mm:ss')
      this.completeTimeForEnd = dateValue2 + 'T' + setTime + 'Z';
    }
    this.isDisable = true;
    let startTimeValue = this.addappointmentForm.controls.startTime.value;
    let endTimeValue = this.addappointmentForm.controls.endTime.value
    let startTimeControlValue: any = this._datepipe.transform(startTimeValue, 'H:mm');
    let endTimeControlValue: any = this._datepipe.transform(endTimeValue, 'H:mm');
    var apptmntDate = this._datepipe.transform(data.dateofAppointment, 'yyyy-MM-dd')
    let startDateTime = apptmntDate + ' ' + startTimeControlValue;
    let EndDateTime = apptmntDate + ' ' + endTimeControlValue;
    if (this.addappointmentForm.controls.endTime.value == "") {
      return;
    }
    else if (new Date(startDateTime) >= new Date(EndDateTime)) {
      this.invalidtime = true
      return;
    }
    else {
      this.invalidtime = false;
    }

    if ((this.addappointmentForm.controls.typeofEventName.value == "Prospect Meeting" || this.addappointmentForm.controls.typeofEventName.value == "Client Meeting") && this.addappointmentForm.controls.prospectId.value == '') {
      this.isValidCompanyName = true
      return;
    }

    if (this.addappointmentForm.controls.companyName.status == 'INVALID') {
      if (this.addappointmentForm.controls.companyName.value == '') {
        return
      }
    }
    if (this.addappointmentForm.controls.opportunitySize.status == 'INVALID') {
      if (this.addappointmentForm.controls.opportunitySize.value == '') {
        return
      }
    }
    if (this.isselectStatus == 'Client' && this.selectedEventName == 'Prospect') {
      this._confirmBox.message = this._userMessages.addInvalidClientMessages;
      this._confirmBox.isVisible = true;
      this.isAssignSelectEventValidorNot = true;
      this.isCancel = false;
      return;
    }

    if ((this.isselectStatus == 'Not a client' || this.isselectStatus == 'Lost Client') && this.selectedEventName == 'Client') {
      this._confirmBox.message = this._userMessages.addInvalidPropsectMessages;
      this._confirmBox.isVisible = true;
      this.isAssignSelectEventValidorNot = true;
      this.isCancel = false;
      return;
    }
    if (this.addappointmentForm.controls.contactId.status == 'INVALID') {
      return;
    }
    if (this.addappointmentForm.controls.contactNumber.status == 'INVALID') {
      return;
    }
    if (this.addappointmentForm.controls.typeofEventName.status == 'INVALID') {
      return;
    }

    let saveData = {
      prospectId: data.prospectId ? parseInt(data.prospectId) : 0,
      subject: data.subject,
      typeofEvent: this.eventTypeId,
      typeofEventName: data.typeofEventName,
      typeOfVisit: data.typeOfVisit,
      contactId: data.contactId.id ? parseInt(data.contactId.id) : 0,
      contactNumber: data.contactId.office1PhoneNumber ? data.contactId.office1PhoneNumber : "",
      startTime: this.completeTimeForStart,
      endTime: this.completeTimeForEnd,
      opportunitySize: data.opportunitySize ? parseInt(this._removeAmountPipe.transform(data.opportunitySize)) : 0,
      notes: data.notes,
      dateofAppointment: this._datepipe.transform(data.dateofAppointment, 'yyyy-MM-dd'),
    };
    this._createsaveAppointmentsUrl.saveAppointments(saveData).subscribe((res) => {
      if (res) {
        this.passEntry.emit('Add Appointment');
        this.submitted = false;
        this.addappointmentForm.invalid;
        this.activeModal.close();
      }
    },
      ((err: any) => {
        if (err.status !== 200 && this.unAthorizesd) {
          this.showError = true;
          return
        }
      }));
  }

  closeError() {
    this.showError = false;
    this.unAthorizesd = '';
  }
  getContactName(event: any) {
    let selectedValue = '0';
    if (event == '0') {
      selectedValue = '0'
    } else {
      selectedValue = event.id
    }
    var scheduledWithContactdetail;
    if (selectedValue != '0') {
      scheduledWithContactdetail = this.contactList.filter(
        (x: any) => x.id == selectedValue);
      this.addappointmentForm.get('contactNumber').setValue(scheduledWithContactdetail[0].office1PhoneNumber);
      this.contactNumberFlag = true;
    } else {
      this.addappointmentForm.get('contactNumber').setValue('');
      this.contactNumberFlag = false;

    }

  }

  get f(): any {
    return this.addappointmentForm.controls;
  }
  cancel() {
    if (!this.addappointmentForm.dirty) {
      this.closeModal();
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
      this.isCancel = true;
      this.isAssignSelectEventValidorNot = false;
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }


  // To Add currency format
  addCurrencyFormat(element: any, control?: AbstractControl) {
    var formattedAmount;
    formattedAmount = element.target.value.replace(/,/g, '');
    formattedAmount = this._amountPipe.transform(formattedAmount, 'USD', '', '1.0-0');
    element.target.value = formattedAmount;
    control?.setValue(element.target.value);
  }

  // To remove amount
  removeCurrencyFormat(element: any, val?: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  //To remove white spaces
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }
  public localToUtc(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  checkValidationForJobFair(val: any) {
    this.addappointmentForm.get('companyName')?.setValidators(null);
    this.addappointmentForm.get('contactId')?.setValidators(null);
    this.addappointmentForm.get('contactNumber')?.setValidators(null);;
    this.addappointmentForm.get('opportunitySize')?.setValidators(null);;

    this.addappointmentForm.get('companyName')?.updateValueAndValidity();
    this.addappointmentForm.get('contactId')?.updateValueAndValidity();
    this.addappointmentForm.get('opportunitySize')?.updateValueAndValidity();
    this.addappointmentForm.get('contactNumber')?.updateValueAndValidity();
  }
  checkValidationForProspectMeeting(val: any) {
    this.addappointmentForm.get('companyName')?.setValidators([Validators.required]);
    this.addappointmentForm.get('contactId')?.setValidators([Validators.required]);
    this.addappointmentForm.get('contactNumber')?.setValidators([Validators.required]);
    this.addappointmentForm.get('opportunitySize')?.setValidators([Validators.required]);

    this.addappointmentForm.get('contactNumber')?.updateValueAndValidity();
    this.addappointmentForm.get('opportunitySize')?.updateValueAndValidity();
    this.addappointmentForm.get('companyName')?.updateValueAndValidity();
    this.addappointmentForm.get('contactId')?.updateValueAndValidity();

  }


}
