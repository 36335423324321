import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private _getappointmentUrl = `${environment.BaseUrl}/api/Appointment/Appointments/PaginatedList`;
  private _createsaveAppointmentsUrl = `${environment.BaseUrl}/api/Appointment/Create`;
  private __deleteAppointmentUrl = `${environment.BaseUrl}/api/Appointment/Delete?id=`;
  private _getAppointmentProspectByIdUrl = `${environment.BaseUrl}/api/Prospect/`;
  private __updateAppointmentByIdUrl = `${environment.BaseUrl}/api/Appointment/Update/`;
  private _getappointmentByIdUrl = `${environment.BaseUrl}/api/Appointment/`;
  private _UpdateAppointmentStatus = `${environment.BaseUrl}/api/Appointment/UpdateAppointmentStatus`;

  constructor(
    private _http: HttpClient
  ) { }

  getAppointmentList(data: any) {
    return this._http.post<any>(this._getappointmentUrl, data);
  }



  //to get Appointment By Id
  getAppointmentById(id: any) {
    return this._http.get(this._getappointmentByIdUrl + id)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  saveAppointments(data: any) {

    return this._http.post(this._createsaveAppointmentsUrl, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //to delete Notes
  deleteAppointmentList(id: any) {
    return this._http.delete(this.__deleteAppointmentUrl + id)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  //to update the recall
  updateAppointment(data: any) {

    return this._http.put(this.__updateAppointmentByIdUrl + data.id, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }

  // UpdateAppointmentStatus api

  UpdateAppointmentStatus(data: any) {

    return this._http.put(this._UpdateAppointmentStatus, data)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      )
  }
}
