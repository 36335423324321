import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { AppointmentListComponent } from './components/appointment-list/appointment-list.component';
import { ViewAppointmentComponent } from './components/view-appointment/view-appointment.component';

const routes: Routes = [
  { path: '', component: AppointmentListComponent },
  { path: NavigationPath.AppointmentList, component: AppointmentListComponent },
  { path: NavigationPath.ViewAppointment, component: ViewAppointmentComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppointmentRoutingModule { }
