import { Component, OnInit } from '@angular/core';
import { EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { AppointmentService } from '../../services/appointment.service';
import { ContactService } from 'src/app/features/contact/services/contact.service';
import { DatePipe } from '@angular/common';
import { WhiteBoardActivityService } from 'src/app/features/white-board/services/white-board-activity.service';
import { AddAppointmentScreen } from '../../errormessage/addapppointment-screen';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { LookupProspectComponent } from 'src/app/features/white-board/components/add-whiteboard/lookup-prospect/lookup-prospect.component';

@Component({
  selector: 'app-edit-appointment',
  templateUrl: './edit-appointment.component.html',
  styleUrls: ['./edit-appointment.component.css']
})
export class EditAppointmentComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() fromAppointmentList: any;
  @Input() userId: any;
  @Input() prospectIdfromAppointmentList: any
  editAppointmentForm: any;
  isSubmitted: boolean = false;
  _confirmBox: ConfirmBox;
  _confirmBox1: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  _AddAppointmentScreen: AddAppointmentScreen;
  value: any = "";
  accountList: any;
  prospectId: any;
  confirmationMsg: any;
  showError: boolean = false;
  errorMessage: any;
  unAthorizesd: any = '';
  contactList: any = [];
  appointmentDeatils: any;
  submitted: boolean = false;
  masterProspectList: any[] = [];
  lookupData: any;
  lookupcomponent: any;
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  prospectValue: string = '';
  errorList: any = [];
  ListDetailData: any;
  id: any;
  getSelectedUser: any;
  selectedContactId: any;
  contactNumber: any;
  minimumDate = new Date('01-01-2024');
  invalidtime: boolean = false;
  completeTimeForStart: any;
  completeTimeForEnd: any;
  isValidUser: boolean = false;
  isValidUserChecked: boolean = false;
  reasonForDeclineCall: boolean = false;
  invalidReasonForDecline: boolean = false;
  invalidReasonForDeclineLength: boolean = false;
  reasonForDecline: string = "";
  disableApproveButton: boolean = false;
  disableDeclineButton: boolean = false;
  public declineReasons: any = [];
  disableJobFairFlag: boolean = true;
  hideContactField: boolean = true;
  isProspectRequired: boolean = true;
  contactNumberFlag: boolean = true;
  lookupFilterData: any;
  selectedEventName: string = '';
  isValidCompanyName: boolean = false;
  CompanyNamerequired: boolean = false;
  isselectStatus: any;
  isAssignSelectEventValidorNot: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    public _activeModal: NgbActiveModal,
    private _createsaveAppointmentsUrl: AppointmentService,
    private _contactService: ContactService,
    public ValidateKey: KeyboardValidation,
    public profileInfoService: ProfileInfoService,
    public datepipe: DatePipe,
    private _fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private _addWBAService: WhiteBoardActivityService,
    private _datepipe: DatePipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    public _amountPipe: CustomeAmountPipe,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
  ) {
    this._AddAppointmentScreen = new AddAppointmentScreen();
    this._confirmBox = new ConfirmBox();
    this._confirmBox1 = new ConfirmBox();
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this.getFormControl()
  }

  ngOnInit(): void {
    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this.getAppointmentById(this.fromAppointmentList);
    this.getContactListForProspect(this.prospectIdfromAppointmentList)
    this.userInformation();
  }


  //get list details By id
  getAppointmentById(id: any) {
    this._createsaveAppointmentsUrl.getAppointmentById(id).subscribe({
      next: ((resp: any) => {
        if (resp) {
          this.ListDetailData = resp.appointmentList;
          this.declineReasons = resp.appointmentDeclineReasons
          this.setFormValue();
          // this.getContactListForProspect(resp.appointmentList.prospectId)

        }
      }),
      error: (() => {

      })
    })
  }


  // Form control fields
  getFormControl() {
    this.editAppointmentForm = this._fb.group({
      prospectId: [''],
      subject: ['', Validators.required],
      prospectName: ['', Validators.required],
      contactName: [''],
      typeOfVisit: ['', Validators.required],
      contactId: ['', Validators.required],
      contactNumber: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      opportunitySize: [''],
      notes: ['', Validators.maxLength(500)],
      dateofAppointment: ['', Validators.required],
      typeofEventName: ['', Validators.required],
    })
  }

  // form value set using patch value
  setFormValue() {
    var dateofAppointment = new Date(this.ListDetailData.dateofAppointment)
    let apmt_date = this.datepipe.transform(dateofAppointment, 'yyyy-MM-dd');

    var finalstartTime = apmt_date + ' ' + this.ListDetailData.startTime;
    var finalendTime = apmt_date + ' ' + this.ListDetailData.endTime;

    var startTime = new Date(finalstartTime);
    var endTime = new Date(finalendTime);


    if (this.ListDetailData.status == "Approved") {
      this.disableApproveButton = true;
    }
    if (this.ListDetailData.status == "Declined") {
      this.disableDeclineButton = true;
    }
    this.checkTypeofEventName(this.ListDetailData.typeofEventName);

    this.editAppointmentForm.patchValue({
      id: this.fromAppointmentList?.id,
      prospectId: this.fromAppointmentList.prospectId,
      subject: this.ListDetailData.subject,
      // prospectName: this.ListDetailData.prospectName,
      typeofEventName: this.ListDetailData.typeofEventName,
      typeOfVisit: this.ListDetailData.typeofVisit,
      contactNumber: this.ListDetailData.contactNumber,
      startTime: startTime,
      endTime: endTime,
      opportunitySize: this.ListDetailData.opportunitySize == 0 ? "" : this._amountPipe.transform(this.ListDetailData.opportunitySize, 'USD', '', '1.0-0'),
      notes: this.ListDetailData.notes,
      dateofAppointment: new Date(dateofAppointment),
      contactId: this.ListDetailData.contactId

    });
    this.editAppointmentForm.controls['prospectName'].patchValue({
      id: this.ListDetailData.prospectId,
      prospectName: this.ListDetailData.prospectName
    });
    this.lookupFilterData = this.ListDetailData.prospectName;
    console.log('this.editAppointmentForm ', this.editAppointmentForm.value)

  }

  getContactName(event: any) {
    let selectedValue = '0';
    if (event == '0') {
      selectedValue = '0'
    } else {
      selectedValue = event
    }
    var scheduledWithContactdetail;
    if (selectedValue != '0') {
      scheduledWithContactdetail = this.contactList.filter(
        (x: any) => x.id == selectedValue);
      this.editAppointmentForm.get('contactNumber').setValue(scheduledWithContactdetail[0]?.office1PhoneNumber);
      this.editAppointmentForm.get('contactNumber').disable();
      this.contactNumberFlag = true;
    } else {
      this.editAppointmentForm.get('contactNumber').setValue('');
      this.contactNumberFlag = false;
    }

  }


  // Update Appointment method
  updateAppointment() {
    this.isSubmitted = true;
    this.isValidCompanyName = false;
    this.CompanyNamerequired = false;
    let data = this.editAppointmentForm.value;
    if (this.editAppointmentForm.controls.contactId.value == '0') {
      this.editAppointmentForm.get('contactNumber')?.setValidators(null);
      this.editAppointmentForm.get('contactNumber')?.updateValueAndValidity();
      this.editAppointmentForm.controls.contactNumber.status = 'VALID';
      this.contactNumberFlag = false
    }
    var dateValue = this._datepipe.transform(data.dateofAppointment, 'yyyy-MM-dd')
    var setTimeForStart = this._datepipe.transform(data.startTime, 'HH:mm:ss')
    var setTimeForEnd = this._datepipe.transform(data.endTime, 'HH:mm:ss')
    //local to utc for start time
    if (data.startTime) {
      var dateTime = dateValue + ' ' + setTimeForStart;
      var newDate = new Date(dateTime);
      var dateValue1 = this._datepipe.transform(newDate, 'yyyy-MM-dd')
      var setTime = this._datepipe.transform(newDate, 'HH:mm:ss')
      this.completeTimeForStart = dateValue1 + 'T' + setTime + 'Z';
    }


    // local to utc for End Time
    if (data.endTime) {
      var endTime = dateValue + ' ' + setTimeForEnd;
      var newDate = new Date(endTime);
      var dateValue2 = this._datepipe.transform(newDate, 'yyyy-MM-dd')
      var setTime = this._datepipe.transform(newDate, 'HH:mm:ss')
      this.completeTimeForEnd = dateValue2 + 'T' + setTime + 'Z';
    }

    this.submitted = true;
    if (!this.editAppointmentForm.valid) {
      return;
    }

    let startTimeValue = this.editAppointmentForm.controls.startTime.value;
    let endTimeValue = this.editAppointmentForm.controls.endTime.value
    let startTimeControlValue: any = this._datepipe.transform(startTimeValue, 'H:mm');
    let endTimeControlValue: any = this._datepipe.transform(endTimeValue, 'H:mm');
    var apptmntDate = this._datepipe.transform(data.dateofAppointment, 'yyyy-MM-dd')
    let startDateTime = apptmntDate + ' ' + startTimeControlValue;
    let EndDateTime = apptmntDate + ' ' + endTimeControlValue;
    if (this.editAppointmentForm.controls.endTime.value == "") {
      return;
    } else if (new Date(startDateTime) >= new Date(EndDateTime)) {
      this.invalidtime = true
      return;
    } else {
      this.invalidtime = false;
    }

    if ((this.editAppointmentForm.controls.typeofEventName.value == "Prospect Meeting" || this.editAppointmentForm.controls.typeofEventName.value == "Client Meeting") && (this.editAppointmentForm.controls.prospectName.value.prospectName == '' || this.editAppointmentForm.controls.prospectName.value.prospectName == undefined)) {
      this.CompanyNamerequired = true
      return;
    }
    if ((this.editAppointmentForm.controls.typeofEventName.value == "Prospect Meeting" || this.editAppointmentForm.controls.typeofEventName.value == "Client Meeting") && this.editAppointmentForm.controls.prospectId.value == '') {
      this.isValidCompanyName = true
      return;
    }

    if (this.isselectStatus == 'Client' && this.selectedEventName == 'Prospect') {
      this._confirmBox1.message = this._userMessages.addInvalidClientMessages;
      this._confirmBox1.isVisible = true;
      this.isAssignSelectEventValidorNot = true;
      return;
    }

    if ((this.isselectStatus == 'Not a client' || this.isselectStatus == 'Lost Client') && this.selectedEventName == 'Client') {
      this._confirmBox1.message = this._userMessages.addInvalidPropsectMessages;
      this._confirmBox1.isVisible = true;
      this.isAssignSelectEventValidorNot = true;
      return;
    }

    let saveData = {
      "id": this.ListDetailData.id,
      "prospectId": data.prospectId ? parseInt(data.prospectId) : 0,
      "subject": data.subject,
      "typeOfVisit": data.typeOfVisit,
      "typeofEventName": data.typeofEventName,
      "contactId": data.contactId ? parseInt(data.contactId) : 0,
      "contactNumber": this.editAppointmentForm.controls.contactNumber.value ? this.editAppointmentForm.controls.contactNumber.value : "",
      "startTime": this.completeTimeForStart,
      "endTime": this.completeTimeForEnd,
      "opportunitySize": data.opportunitySize ? parseInt(this._removeAmountPipe.transform(data.opportunitySize)) : this.ListDetailData.opportunitySize,
      "notes": data.notes,
      "dateofAppointment": this._datepipe.transform(data.dateofAppointment, 'yyyy-MM-dd'),
    };
    this._createsaveAppointmentsUrl.updateAppointment(saveData).subscribe(resp => {
      if (resp) {
        this.passEntry.emit('Edit Appointment');
        this.closeModal();
      }
    },
      ((error: any) => {
        if (error.error.status == 403) {
          this.showError = true
        }
      })
    )
  }


  getCompanyList(fieldName: any, val: any) {
    this.lookupFilterData = val.query;
    if (fieldName && val.query) {
      let encode = val.query.replaceAll('&', '%26')
      this._addWBAService.getTypeheadList(fieldName, encode).subscribe(resp => {
        if (fieldName == 'companyName') {
          this.accountList = resp.prospectList;
        }
      })
    }
  }
  openLookupModal(value: any) {
    if (value === "companyName") {
      this.getProspectList()
    }
  }

  //Loading prospect List.
  getProspectList() {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "prospectName",
      "searchText": "",
      "filterJson": "",
      "gridFilterJson": ""
    }
    const modalRef = this.modalService.open(LookupProspectComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {

    })
    modalRef.componentInstance.lookupFilterData = this.lookupFilterData;
    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.hideAddButton = true;
    modalRef.componentInstance.selectedEventName = this.selectedEventName;

    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.isselectStatus = data.prospectInformation.status;
        this.editAppointmentForm.controls['prospectName'].patchValue({
          id: data.id,
          prospectName: data.prospectName
        });
        this.lookupFilterData = data.prospectName
        this.selectItem(data, 'companyName');
      }
    });
    //     }
    //   },
    //   (error: any) => {

    //   }
    // );
  }

  selectItem(item: any, type: any) {
    this.prospectId = item?.id;
    this.prospectValue = item.prospectName;
    this.lookupFilterData = item.prospectName;
    this.CompanyNamerequired = false;
    if (this.prospectId) {
      this.getContactListForProspect(this.prospectId);
    }
    if (type == 'companyName') {
      this.editAppointmentForm.get('prospectId').setValue(this.prospectId);
    }

  }

  changeProspectValue() {
    this.editAppointmentForm.controls.prospectId.value = ''
  }
  lookUpDataFilterChanges(val: any) {
    this.lookupFilterData = "";
    this.editAppointmentForm.controls.prospectId.value = ''
  }
  closeModal() {
    this._activeModal.close();
  }

  cancel() {
    if (!this.editAppointmentForm.dirty) {
      this.closeModal();
    } else {
      this.confirmationMsg = "modelClose"
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
  }

  confirmBoxCancel() {
    this._confirmBox.isVisible = false;
    this.activeModal.close();
  }

  get f() {
    return this.editAppointmentForm.controls;
  }

  // To Add currency format
  addCurrencyFormat(element: any, control?: AbstractControl) {
    var formattedAmount;
    formattedAmount = element.target.value.replace(/,/g, '');
    formattedAmount = this._amountPipe.transform(formattedAmount, 'USD', '', '1.0-0');
    element.target.value = formattedAmount;
    control?.setValue(element.target.value);
  }

  // To remove amount
  removeCurrencyFormat(element: any, val?: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  //To remove white spaces
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }


  // get contact list using prospect by id
  getContactListForProspect(prospectId: any) {
    this._contactService.contactsList(prospectId).subscribe((resp) => {
      if (resp) {
        this.contactList = resp.contactList;
        // setTimeout(() => {
        //   this.f.contactId.patchValue(this.ListDetailData.contactId
        //   );
        // }, 500);

      }
    });
  }



  clearValue() {
    this.value = "";
  }



  closeError() {
    this.showError = false;
  }
  public localToUtc(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  userInformation() {
    let userDetailsData = JSON.parse(localStorage.getItem('userDetails')!)
    if (userDetailsData) {
      userDetailsData?.user?.roles.forEach((x: any) => {
        if (x.roleName == 'Admin' || x.roleName == 'Sales Director' || x.roleName == "Production Specialist" || x.roleName == 'Client Service Level 4') {
          this.isValidUser = true;
        }
      })
      let roleExist = userDetailsData.user.roles.find((role: any) => role.roleName == 'Admin' || role.roleName == 'Sales Director');
      if (roleExist) {
        this.isValidUserChecked = true;
      }
      else this.isValidUserChecked = false;
    }
  }

  getreasonForDecline() {
    this.reasonForDeclineCall = true;
    this._confirmBox.isVisible = true;
  }
  validateforReason() {
    if (this.reasonForDecline.length > 500) {
      this.invalidReasonForDeclineLength = true;
      this.invalidReasonForDecline = true;
    }
    else {
      this.invalidReasonForDeclineLength = false;
      this.invalidReasonForDecline = false;
    }
  }
  UpdateAppointmentStatus(val: any) {

    if (val == 'Declined') {
      if (!this.reasonForDecline || this.reasonForDecline == "") {
        this.invalidReasonForDecline = true;
        this.invalidReasonForDeclineLength = false;
        return
      }
      if (this.reasonForDecline.length > 500) {
        this.invalidReasonForDecline = true;
        this.invalidReasonForDeclineLength = true;
        return
      }
    }

    let request = {
      AppointmentId: this.fromAppointmentList,
      AppointmentDeclineReason: this.reasonForDecline,
      Status: val,
      UserId: this.userId
    }
    this._createsaveAppointmentsUrl.UpdateAppointmentStatus(request).subscribe((resp: any) => {
      this._confirmBox.isVisible = false;
      this.reasonForDeclineCall = false;
      this.reasonForDecline = "";
      if (val == 'Approved') {
        this.disableApproveButton = true;
        this.disableDeclineButton = false;
      }
      if (val == 'Declined') {
        this.disableDeclineButton = true;
        this.disableApproveButton = false;
      }
      this.passEntry.emit('Edit Appointment');
      this.closeModal();

    },
      (err: any) => {
        this.showError = true;
        if (err.status !== 200) {
          if (err.status === 400) {
            this.showError = true;
            let obj = err.error.errors;
            var arr = Object.keys(obj).map((key) => ({
              type: key,
              value: obj[key],
            }));
            this.errorList = [];
            this.errorMessage = arr[0].value[0];
            this.errorList.push(this.errorMessage);
          } else {

            this.showError = true;
            this.errorMessage = "Error : " + err.message + ", Status: " + err.status;
            this.errorList.push(this.errorMessage);
          }
        }
      });

  }

  checkTypeofEventName(res: any) {
    let value1 = res;
    this.disableJobFairFlag = true;
    this.isProspectRequired = true;
    this.hideContactField = true;
    if (value1 == 'Job Fair') {
      this.isProspectRequired = false;
      this.disableJobFairFlag = false;
      this.hideContactField = false;
      this.contactNumberFlag = false;
      this.checkValidationForNotRequired(value1);
    }
    else if (value1 == 'Event / Conference') {
      // value1 == 'Event Conference' ||
      this.isProspectRequired = false;
      this.disableJobFairFlag = true;
      this.hideContactField = false;
      this.contactNumberFlag = false;
      this.selectedEventName = '';
      this.checkValidationForNotRequired(value1);
    }
    else if (value1 == 'Company Event') {
      this.isProspectRequired = false;
      this.disableJobFairFlag = false;
      this.hideContactField = false;
      this.contactNumberFlag = false;
      this.checkValidationForNotRequired(value1);
    }
    else if (value1 == 'Prospect Meeting') {
      this.isProspectRequired = true;
      this.disableJobFairFlag = true;
      this.hideContactField = true;
      this.contactNumberFlag = true;
      this.selectedEventName = 'Prospect';
      this.checkValidationForProspectMeeting(value1);
    }
    else if (value1 == 'Client Meeting') {
      this.isProspectRequired = true;
      this.disableJobFairFlag = true;
      this.hideContactField = true;
      this.contactNumberFlag = true;
      this.selectedEventName = 'Client';
      this.checkValidationForProspectMeeting(value1);
    }
  }
  checkValidationForNotRequired(val: any) {
    this.editAppointmentForm.get('prospectName')?.setValidators(null);
    this.editAppointmentForm.get('contactId')?.setValidators(null);
    this.editAppointmentForm.get('contactNumber')?.setValidators(null);;
    this.editAppointmentForm.get('opportunitySize')?.setValidators(null);;

    this.editAppointmentForm.get('prospectName')?.updateValueAndValidity();
    this.editAppointmentForm.get('contactId')?.updateValueAndValidity();
    this.editAppointmentForm.get('opportunitySize')?.updateValueAndValidity();
    this.editAppointmentForm.get('contactNumber')?.updateValueAndValidity();
  }
  checkValidationForProspectMeeting(val: any) {
    this.editAppointmentForm.get('prospectName')?.setValidators([Validators.required]);
    this.editAppointmentForm.get('contactId')?.setValidators([Validators.required]);
    this.editAppointmentForm.get('contactNumber')?.setValidators([Validators.required]);
    this.editAppointmentForm.get('opportunitySize')?.setValidators([Validators.required]);

    this.editAppointmentForm.get('contactNumber')?.updateValueAndValidity();
    this.editAppointmentForm.get('opportunitySize')?.updateValueAndValidity();
    this.editAppointmentForm.get('prospectName')?.updateValueAndValidity();
    this.editAppointmentForm.get('contactId')?.updateValueAndValidity();

  }
}
